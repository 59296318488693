<script>
/** 页面tab菜单组件，属性tabItems传入菜单数组，格式:[{label,value},......] */
import { computed, onMounted, watchEffect, ref } from "@vue/composition-api";
export default {
  props: {
    value: {
      type: Number | String,
      default: 0,
    },
    tabItems: {
      type: Array,
      default: function () {
        return [];
      },
    },

    tabsStyle: {
      type: Object,
      default: () => ({}),
    },
    tabWidth: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#2d2e32",
    },
    activeColor: {
      type: String,
      default: "#c51e3a",
    },
  },
  setup(props, { emit }) {
    const tabsView = ref();
    const tabs = ref();
    const onSelected = (selectedId) => {
      if (selectedId !== props.value) {
        emit("current-change", selectedId);
      }
      emit("input", selectedId);
    };

    const tabsLeft = ref(0);
    let leftIdx = 0;

    const scrollLeft = () => {
      const tabItemDomArr = tabs.value.childNodes;
      
      if (
        tabsLeft.value <
        tabsView.value.offsetWidth -
          tabs.value.offsetWidth
      )
        return;

      const offset =
        tabItemDomArr[leftIdx + 1].offsetLeft -
        tabItemDomArr[leftIdx].offsetLeft;

      if (leftIdx < tabItemDomArr.length - 2) leftIdx++;
      //tabs.value.scrollLeft += offset;
      tabsLeft.value -= offset;
      
    };

    const scrollRight = () => {
      const tabItemDomArr = tabs.value.childNodes;
      if (
        tabsLeft.value >= 0
      )
        return;

      const offset =
        tabItemDomArr[leftIdx].offsetLeft -
        tabItemDomArr[leftIdx - 1].offsetLeft;

      if (leftIdx < tabItemDomArr.length - 1) leftIdx--;
      //tabs.value.scrollLeft -= offset;
      tabsLeft.value += offset;
    };

    return {
      onSelected,
      tabsView,
      tabs,
      tabsLeft,
      scrollLeft,
      scrollRight,
    };
  },
};
</script>

<template>
  <!-- Tab菜单 -->
  <div class="page-tab">
    <div @click="scrollLeft" class="opr left">
      <i class="fa fa-chevron-left fa-lg"></i>
    </div>
    <div
      ref="tabsView"
      style="position: relative; height: 28px; overflow: hidden"
      :style="tabsStyle"
    >
      <div ref="tabs" class="row-tab" :style="{ left: tabsLeft + 'px' }">
        <div
          v-for="(item, idx) in tabItems"
          :key="idx + '-' + item.value"
          class="tab-item"
          :class="{ active: value === item.value }"
          :style="{
            marginLeft: idx == 0 ? '0px' : '30px',
            color: value === item.value ? activeColor : color,
          }"
          @click="onSelected(item.value)"
        >
          {{ item.label }}
          <div
            class="tab-item-after"
            :style="{
              backgroundColor: activeColor,
              width: value === item.value ? '100%' : '0px',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div @click="scrollRight" class="opr right">
      <i class="fa fa-chevron-right fa-lg"></i>
    </div>
  </div>
</template>



<style scoped>
@import url(./XTab.css);
</style>