<script>
import {
  ref,
  nextTick,
  watch,
  onMounted,
  computed,
} from "@vue/composition-api";
import { get } from "@/common";
import store from "@/common/store";
import vueSeamlessScroll from "vue-seamless-scroll";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import XTab from "../../components/XTab.vue";

export default {
  components: {
    vueSeamlessScroll,
    Swiper,
    SwiperSlide,
    XTab,
  },
  directives: {
    swiper: directive,
  },
  setup() {
    const active = ref(3);
    const tabItems = ref([
      { value: 1, label: "Java" },
      { value: 2, label: "C/C++" },
      { value: 3, label: "Python" },
      { value: 4, label: "前端" },
      { value: 5, label: "大数据" },
      { value: 6, label: "人工智能" },
      { value: 7, label: "软件测试" },
      { value: 8, label: "UI设计" },
      { value: 9, label: "动漫开发" },
      { value: 10, label: "影视后期" },
      { value: 11, label: "网络维护" },
      { value: 12, label: "计算机考研" },
      { value: 13, label: "嵌入式" },
      { value: 14, label: "软件运维" },
      { value: 15, label: "Go开发" },
    ]);

    const newsList = ["food1.jpeg", "food2.jpeg", "food3.jpeg", "food4.jpeg", "food5.jpeg", "food6.jpeg", "food7.jpeg", "food8.jpeg", "food9.jpeg", "food10.jpeg"];
    const optionLeft = computed(() => ({
      step: 1, // 数值越大速度滚动越快
      limitMoveNum: 2, //newsList.length / 2 - 1, // 开始无缝滚动的数据量 this.dataList.length
      hoverStop: true, // 是否开启鼠标悬停stop
      direction: 2, // 0向下 1向上 2向左 3向右
      openWatch: true, // 开启数据实时监控刷新dom
      singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
      singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
      waitTime: 0, // 单步运动停止的时间(默认值1000ms)
    }));

    const mySwiper = ref();

    onMounted(() => {
      console.log(mySwiper.value);
      mySwiper.value.$swiper.slideTo(3, 1000, false);
    });

    const swiperOptions = {
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      // Some Swiper option/callback...
      loop: true,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };

    return {
      active,
      tabItems,
      newsList,
      optionLeft,
      swiperOptions,
      mySwiper,
    };
  },
};
</script>
<template>
  <div class="index">
    <x-tab
      v-model="active"
      :tabItems="tabItems"
      style="margin-top: 50px"
      :tabs-style="{ width: '500px' }"
      color="#887799"
      active-color="#33AA66"
    ></x-tab>

    <vue-seamless-scroll
      :data="newsList"
      :class-option="optionLeft"
      class="seamless"
    >
      <div class="item">
        <div
          class="item-info"
          v-for="item in newsList"
          
          :key="item"
        >
          <img :src="`${$baseUrl}/st-static/${item}`" style="width:100%;height:100%;" />
        </div>
      </div>
    </vue-seamless-scroll>

    <swiper
      ref="mySwiper"
      :options="swiperOptions"
      style="width:1000px;height: 650px; border: 1px solid #DDDDDD"
    >
      <swiper-slide><img :src="`${$baseUrl}/st-static/food1.jpeg`" style="width:100%;height:100%;"/></swiper-slide>
      <swiper-slide><img :src="`${$baseUrl}/st-static/food2.jpeg`" style="width:100%;height:100%;"/></swiper-slide>
      <swiper-slide><img :src="`${$baseUrl}/st-static/food3.jpeg`" style="width:100%;height:100%;"/></swiper-slide>
      <!-- <swiper-slide>Slide 4</swiper-slide>
      <swiper-slide>Slide 5</swiper-slide> -->
      <div class="swiper-pagination" slot="pagination"></div>

      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>


    </swiper>

    <div
      v-for="n in 10"
      :key="n"
      style="
        border: 1px solid red;
        width: 100px;
        height: 100px;
        margin: 10px auto;
      "
    >
      占位{{ n }}
    </div>
  </div>
</template>
<style scoped>
.index {
}

.seamless {
  overflow: hidden;

  width: 1000px;
  margin: 20px auto;
}

.item {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.item-info {
  flex: none;
  width: 200px;
  height:130px;
  text-align: center;
  line-height: 50px;
  border: 1px solid #aaccaa;
}

.item-info:not(:first-child) {
  margin-left:20px;
}

.swiper-button-prev{
  color:#999999;
}




</style>

<style>
.swiper-pagination-bullet{
  width:20px !important;
  height:20px  !important;
  background-color: black;
   opacity: 1;
}

.swiper-pagination-bullet-active{
  background-color:yellow;
 
}
</style>